import Lang from "../../common/lang/Lang";
import { SystemNotificationModel } from "../../model";

export const urls = {
  home: "/settings",
  list: "/settings/system-notifications",
}

export const ListBreadcrumb = [
  {
    label: Lang.settings,
    url: urls.home
  },
  {
    label:"Notification",
    url: "#"
  }
];

export const CreateBreadcrumb = [
  {
    label: Lang.settings,
    url: urls.home
  },
  {
    label: "Notification",
    url: urls.list
  },
  {
    label: Lang.create,
    url: '#'
  }
];

export const EditBreadcrumb = [
  {
    label: Lang.settings,
    url: urls.home
  },
  {
    label: "Notification",
    url: urls.list
  },
  {
    label: Lang.edit,
    url: '#'
  }
];


export interface IState {
  id: number;
  data?: SystemNotificationModel
}
