import React from "react";
import _ from "lodash";
import {Form} from "../Data";
import {CommonSubmitButton, isEmpty, LoaderType, RenderForm, showNotification, SubmitButton, SwitchLoader} from "../../../common";
import API from "../../../common/AxoisClient";
import { useHistory } from "react-router-dom";
import {AgeGroupModel, TeamModel, UserModel} from "../../../model";
import { ClubService, QuestionnaireService} from "../../../services";
import Lang from "../../../common/lang/Lang";

export const UserForm = ({update, ...props}) => {
    let history = useHistory();
    const [form, setForm] = React.useState({} as Form);
    const [oldAgeGroup, setOldAgeGroup] = React.useState<number>(0);
    const [ageGroup, setAgeGroup] = React.useState([] as Array<AgeGroupModel>);
    const [teams, setTeams] = React.useState([] as Array<TeamModel>);

    const handleChange = (event: React.ChangeEvent<HTMLElement>) => {
        const target : any = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setForm({...form, [name]: value})
    }

    React.useEffect(() => {
        async function init() {
            const ageGroupList: Array<AgeGroupModel> = await QuestionnaireService.getAgeGroups();
            setAgeGroup(ageGroupList);
        }
        init()
    },[])

    React.useEffect(() => {
        async function init() {
            const { teams } : any = form;
            if(teams.length > 0) {
                const teamList: Array<TeamModel> = await ClubService.teamsList({ 
                    age_group_id : form.age_group_id,
                    club_id: teams[0].club_id,
                });
                setTeams(teamList);
            }
        }
        if(form.age_group_id > 0 && update === true && form.role_id == 4) {
            init()
        }
    },[form.age_group_id])


    React.useEffect(() => {
        setForm(_.get(props, "form", {} as UserModel))
        setOldAgeGroup(_.get(props, "form.age_group_id", 0));
    }, [props.form])

    const FormInputs = [
        {
            "name" : "role_id",
            "value" : _.get(form, "role_id", ""),
            "onChange": handleChange,
            "required": true,
            "placeholder": "Select role",
            "type": "select",
            "label": "Role",
            "options": _.get(props, "roles", [])
        },
        {
            "name" : "age_group_id",
            "value" : _.get(form, "age_group_id", ""),
            "onChange": handleChange,
            "placeholder": "Select age group",
            "type": "select",
            "required": form.role_id == 4,
            "label": "Age group",
            "options": ageGroup,
            "show": form.role_id == 4
        },
        
        {
            "name" : "first_name",
            "value" : _.get(form, "first_name", ""),
            "onChange": handleChange,
            "required": true,
            "placeholder": "Enter first name",
            "type": "text",
            "label": "First Name"
        },
        {
            "name" : "last_name",
            "value" : _.get(form, "last_name", ""),
            "onChange": handleChange,
            "required": true,
            "placeholder": "Enter last name",
            "type": "text",
            "label": "Last Name"
        },
        {
            "name" : "email",
            "value" : _.get(form, "email", ""),
            "onChange": handleChange,
            "required": true,
            "placeholder": "Enter email",
            "type": "email",
            "label": "Email"
        },
       {
            "name" : "username",
            "value" : _.get(form, "username", ""),
            "onChange": handleChange,
            "required": true,
            "placeholder": "Enter username",
            "type": "text",
            "label": "Username"
        },
        {
            "name" : "password",
            "value" : _.get(form, "password", ""),
            "onChange": handleChange,
            "placeholder": "Enter password",
            "type": "password",
            "label": "Password"
        },
        {
            "name" : "mobile_no",
            "value" : _.get(form, "mobile_no", ""),
            "onChange": handleChange,
            // "required": true,
            "placeholder": "Enter mobile no",
            "type": "text",
            "label": "Mobile No."
        },
        {
            "name" : "dob",
            "value" : _.get(form, "dob", ""),
            "onChange": handleChange,
            // "required": true,
            "placeholder": "Enter date of birth",
            "type": "date",
            "label": "Date of birth"
        }
    ];
    if(update === true) {
        FormInputs.splice(2, 0, {
            "name" : "team_id",
            "value" : _.get(form, "team_id", ""),
            "onChange": handleChange,
            "placeholder": "Select Team",
            "type": "select",
            "required": form.age_group_id != oldAgeGroup,
            "label": "Team",
            "options": teams,
            "show": form.age_group_id != oldAgeGroup
        } as any);
    }
    
    function isDisable() {
      return FormInputs.reduce((acc, val) => acc ? acc :
        (isEmpty(val.value) && val.required && val.show === undefined) || (isEmpty(val.value) && val.required && val.show), false);
    }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
      if(update){
          API.post(`users/users/${props.id}`, {...form, '_method': 'PUT'}).then((res) => {
              showNotification("success", res.data.message);
              history.push(`/users/users`)
          }).catch(() => {})
      } else {
          API.post('users/users', form).then((res) => {
              showNotification("success", res.data.message);
              history.push(`/users/users`)
          }).catch(() => {})
      }
  }

    return(
        <form className="kt-form" id={'customer_form'} onSubmit={handleSubmit}>
          <div className="kt-portlet__body">
            <div className={'row'}>
              <RenderForm items={FormInputs}/>
            </div>
            <div className="row">
                <div className="col-md-12 col-lg-12">                
                    <label>
                        <input onChange={handleChange} id="sendemail" name="sendemail" type="checkbox" value="true"/>
                        &nbsp; Send welcome email to user
                    </label>
                </div>
              </div>
          </div>
          <div className="kt-portlet__foot">
                <div className="kt-form__actions">
                    <div className="row">
                    <div className="col-lg-10">
                        {
                            form.role_id == 4 && 
                            <div className='text text-danger'>
                                Make sure to change the team if changing the age group of player.
                            </div>
                        }
                    </div>
                    <div className="col-lg-2 kt-align-right">
                        <SubmitButton disabled={isDisable()} className="btn btn-primary" label={Lang.submit} />
                    </div>
                    </div>
                </div>
            </div>
        </form>
      )
}
