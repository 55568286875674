import API from "../common/AxoisClient";

export class SystemNotificationService {


  static async store(form) {
    const store = await API.post("settings/system-notifications", form);
    return  store.data;
  }

  static async update(form, id) {
    const store = await API.post(`settings/system-notifications/${id}`, {...form, '_method': 'PUT'});
    return  store.data;
  }

  static async getInfo(id) {
    const store = await API.get(`settings/system-notifications/${id}`, {});
    return  store.data;
  }


  static async dataForNotificationForm() {
    const store = await API.get("settings/system-notifications/create");
    return  store.data;
  }


  
}