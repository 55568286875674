import * as React from 'react';
import { ClubModel} from "../../model";
import {IState as CommonState} from "../../common/State";
import {actionButton, ActionLink, CreateLink, ExportButton, GeneratePermitLink} from "../../common";
import {RouteComponentProps} from "react-router";
import {ListBreadcrumb} from "./Data";
import { EditLink, ShowRecords, Icons} from "../../common";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
import _ from "lodash";
import {list_key} from "../../helpers/OrderHelper";
import Filters from "./Filters";
import {humanifyDate, listingStatus} from "../../helpers/UserHelper";
import {QuestionnaireSessionModel} from "./modal/QuestionnaireSession";
import {get} from "lodash";
import {Link} from "react-router-dom";
import {default as Lang} from "../../common/lang/Lang";
import { ClubFeaturesModel } from './modal/ClubFeatures';

interface IState extends CommonState {
  show: boolean;
  features_show: boolean;
  club_id: number;

}

export class Clubs extends React.Component<RouteComponentProps, IState> {
  state: IState;
  records:React.RefObject<ShowRecords> = React.createRef();
  

  showContent = (data: ClubModel, index: number) => {
    
    let cols = [];
    cols.push(...[
      <td key={index}>{this.records.current.checkbox(data.id)}</td>,
      this.records.current.createdTd("club_code", data.club_code),
      this.records.current.createdTd("name", data.name),
      this.records.current.createdTd("user_id", data.coach_director != null ? <Link to={`/users/users/info/${data.coach_director.id}`}>{data.coach_director.name}</Link> : 'N/A' ),
      this.records.current.createdTd("psychologist_id", data.psychologist ? <Link to={`/users/users/info/${data.psychologist.id}`}>{data.psychologist.name}</Link> : "N/A"),
      this.records.current.createdTd("physiotherapist_id", data.physiotherapist ? <Link to={`/users/users/info/${data.physiotherapist.id}`}>{data.physiotherapist.name}</Link> : "N/A"),
      this.records.current.createdTd("dof_id", data.dof ? <Link to={`/users/users/info/${data.dof.id}`}>{data.dof.name}</Link> : "N/A"),
      this.records.current.createdTd("club_info", () => {
        return (
          <div>
            <p>Total Team: {data.teams_count}</p>
            <p>Total Members: {data.team_members_count}</p>
          </div>
        )
      }),
      this.records.current.createdTd("logo", <img src={data.logo} className={"img-responsive"} height={100} width={100}/>),
      this.records.current.createdTd("status", () => listingStatus(data.status)),
      this.records.current.createdTd("created_at", humanifyDate(data.created_at)),
      this.records.current.createdTd("action", () =>  this.rowActionButtons(data))
    ]);
    cols = _.compact(cols);
    return (
      <tr key={list_key(data.id)}>{cols}</tr>
    );
  }

  filterRecords = (data?: any) => {
    if( this.records && this.records.current !== null){
      if(data !== undefined){
        this.records.current.setFormFields(data);
      }else {
        this.records.current.setFormFields();
      }
    }
  }


  rowActionButtons = (data: ClubModel) => {
    return (
      <React.Fragment>
        <EditLink scope={'clubs.update'} to={`${this.props.match.url}/edit/${data.id}`} title={"Edit"} />
        <ActionLink scope={['club-settings.store', 'club-settings.update']} to={`${this.props.match.url}/${data.id}/settings`} title={"Settings"}>
          <i className={Icons.setting} />
        </ActionLink>
        <span style={{overflow: "visible", position: "relative", width: '110px'}}>
          <button className={"btn btn-sm"} onClick={e => this.setState({show: true, club_id: data.id})} title={"Questionnaire Session"} ><i className={Icons.question}/></button>
        </span>
        <span style={{overflow: "visible", position: "relative", width: '110px'}}>
          <button className={"btn btn-sm"} onClick={e => this.setState({features_show: true, club_id: data.id})} title={"Features"} ><i className={Icons.football}/></button>
        </span>
        
      </React.Fragment>
    )
  }

  render() {
    return (
        <ListingLayout breadcrumb={ListBreadcrumb}>
          <ListHeader title={"Clubs"} icon={Icons.club} >
            <ExportButton records={this.records} fileName={"Clubs"}/>
            &nbsp;
            <CreateLink to={`/clubs/clubs/create`} scope={'clubs.store'} />
            &nbsp;
            <GeneratePermitLink className={'btn btn-brand btn-elevate btn-icon-sm'}  scope={'clubs.store'} to={`/clubs/clubs/import`} ><i className={Icons.download} /> Import Team</GeneratePermitLink>
          </ListHeader>

          <ListForm
            form={<Filters getResults={this.filterRecords}
            />}
            table={(
              <ShowRecords
                url={"/clubs/clubs"}
                showRecords={this.showContent}
                ref={this.records}
                isNeedAction={true}
                actionButtons={actionButton({records: this.records, 'url': "clubs/clubs/bulk-action", "module": 'clubs'})}
                rowActions={this.rowActionButtons}
               />
            )}
          />
          <QuestionnaireSessionModel
            show={get(this.state, "show", false)}
            onHide={() => this.setState({show: false})}
            club={get(this.state, "club_id", 0)}
          />
          <ClubFeaturesModel 
            show={get(this.state, "features_show", false)}
            onHide={() => this.setState({features_show: false})}
            club={get(this.state, "club_id", 0)}
          />

        </ListingLayout>
    )
  }
}
