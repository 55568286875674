import React, { useState } from 'react';
import {Modal} from "react-bootstrap";
import API from "./../../../common/AxoisClient";
import { showNotification } from '../../../common';
declare var window: any;

export const ClubFeaturesModel = (props) => {

  const [form, setForm] = React.useState({} as any);
  const {club, onHide, show} = props;


  React.useEffect(() => {
    if(club > 0 && show) {
      API.get(`clubs/${club}/features`).then(res => {
        const {data} = res;
        setForm(data);
        window.KTApp.initTooltips();
      }).catch(e => {})
    }
  }, [club, show])
 
  function hideModel() {
    setForm({} as any)
    onHide();
  }
  
  return(
    <Modal
      {...props}
      onHide={hideModel}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Club Features
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DataTable {...form} />
      </Modal.Body>
    </Modal>
  )
};

const DataTable = ({ features, columns, matrix }) => {
  const [selectedFeatures, setSelectedFeatures] = useState({});
  if(matrix === undefined) {
    return null;
  }
  // Map data for quick lookup
  const matrixMap = {};
  matrix.forEach(item => {
    const ageGroupKey = item.age_group_id ? `age_${item.age_group_id}` : null;
    const roleKey = item.role_id ? `role_${item.role_id}` : null;

    const key =  `${item.feature_id}-${ageGroupKey || roleKey}`;
    matrixMap[key] = item;
  });

  const handleCheckboxChange = async (key, matrix, checked) => {
    const updatedFeatures = {
      ...selectedFeatures,
      [key]: checked,
    };
    setSelectedFeatures(updatedFeatures);

    // Send the updated value to the server
    try {
      const response:any = await  API.post(`clubs/${matrix.club_id}/features/${matrix.id}`, {
        is_enabled: checked,
      });
      const {data } = response;
      if(data.status) {
        showNotification("success", data.message);
      }
    } catch (error) {
      console.error('Failed to update feature', error);
    }
  };

  // Render the table
  return (
    <table className='table table-bordered table-striped'>
      <thead className='kt-datatable__head'>
        <tr>
          <th>Feature</th>
          {columns.map((col) => (
            <th key={col.key}>{col.name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {features.map((feature) => (
          <tr key={feature.id}>
            <td>{feature.name}</td>
            {columns.map((col) => {
              const key = `${feature.id}-${col.key}`;
              const cell = matrixMap[key];
              const isChecked = selectedFeatures[key] ?? (matrixMap[key]?.is_enabled ?? false);
              const isDisabled = matrixMap[key]?.is_locked ?? false;
              return (
                <td key={key} className={`table-cell ${cell?.is_enabled ? "enabled" : "disabled"}`}  >                  
                 <span className="kt-switch leftSwitchbtn" data-skin={'dark'} data-toggle={'kt-tooltip'} title={isDisabled ? "This feature is locked" : ""}>
                    <label>
                      <input type={'checkbox'}  
                        checked={isChecked}
                        disabled={isDisabled}
                        onChange={(e) => handleCheckboxChange(key, cell, e.target.checked)}/>
                      <span/>
                    </label>
                  </span>
                  
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
