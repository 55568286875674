import React from "react";
import {Modal, ModalProps} from "react-bootstrap";
import _ from "lodash";
import {CommonSubmitButton, isEmpty, LoaderType, RenderForm, SwitchLoader} from "../../../common";
import {ClubService} from "../../../services";
import {UserModel} from "../../../model";


interface addPlayerProps extends ModalProps{
  onPlayerAdd: (user: UserModel) => void,
  role_id: number,
  ageGroups: Array<any>
}

export const AddPlayer = ({onPlayerAdd, role_id, ...props} : addPlayerProps) => {

  const [user, setUser] = React.useState({} as UserModel);

  React.useEffect(() => {
    setUser({...user, role_id: role_id})
  }, [role_id])

  if(role_id === 0)
    return null;

  const handleChange = (event: React.ChangeEvent<HTMLElement>)  => {
    const target : any = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    setUser({...user, [name]: value})
  }

  const FormInputs = [
    {
      "name" : "first_name",
      "value" : _.get(user, "first_name", ""),
      "onChange": handleChange,
      "required": true,
      "placeholder": "Enter first name",
      "label": "First name",
    },
    {
      "name" : "last_name",
      "value" : _.get(user, "last_name", ""),
      "onChange": handleChange,
      "required": true,
      "placeholder": "Enter last name",
      "label": "Last name",
    },
    {
      "name" : "email",
      "type" : "email",
      "value" : _.get(user, "email", ""),
      "onChange": handleChange,
      "required": true,
      "placeholder": "Enter email",
      "label": "Email",
    },
    {
        "name" : "username",
        "value" : _.get(user, "username", ""),
        "onChange": handleChange,
        "required": true,
        "placeholder": "Enter username",
        "type": "text",
        "label": "Username"
    },
    {
      "name" : "mobile_no",
      "value" : _.get(user, "mobile_no", ""),
      "onChange": handleChange,
      "required": true,
      "placeholder": "Enter mobile no",
      "label": "Mobile no",
    },
  ];
  if(role_id === 4){
    FormInputs.push({
      "name" : "age_group_id",
      "value" : _.get(user, "age_group_id", ""),
      "onChange": handleChange,
      "required": true,
      "type": "select",
      "placeholder": "Select Age Group",
      "label": "Age Group",
      "options": _.get(props, "ageGroups", [])
    } as any )
  }

  const handleSubmit = async event => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON)
    try{
      const userInfo:UserModel = await ClubService.addPlayer(user);
      onPlayerAdd(userInfo);
      setUser({} as UserModel);
      props.onHide();
    } catch (e) {
     
    }
  }
  const isDisable = () => FormInputs.reduce((acc, val) => acc ? acc : isEmpty(val.value) && val.required, false);

  console.log(user);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="add_player"
    >
      <Modal.Header closeButton>
        <Modal.Title id="add_player">
          Add {role_id === 4 ? "Player": "Coach"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="kt-form" id={'customer_form'} onSubmit={handleSubmit}>
          <div className="kt-portlet__body">
            <div className={'row'}>
              <RenderForm items={FormInputs}/>
            </div>
          </div>
          <div className="col-md-6 col-lg-6">                
            <label>
                <input onChange={handleChange} id="sendemail" name="sendemail" type="checkbox" value="true"/>
                &nbsp; Send welcome email to user
            </label>
          </div>
          <CommonSubmitButton disabled={isDisable()}/>
        </form>

      </Modal.Body>

    </Modal>
  )
}