import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {Dashboard} from "./dashbaord/Dashboard";
import {Listing} from "./users/customers/Listing";
import {AdminUsers} from "./users/admin-users/Index";
import {CustomerCreate} from "./users/customers/Create";
import {EmailTemplates} from "./settings/email-templates/Index";
import {CreateEmailTemplates} from "./settings/email-templates/create";
import {EditEmailTemplates} from "./settings/email-templates/edit";
import {ActivityLogs} from "./logs/activity-logs/Index";
import {Auth} from "./Auth/Auth";

import {RolesListing} from "./permissions/role/Listing";
import {RoleCreate} from "./permissions/role/Create";
import {RoleEdit} from "./permissions/role/Edit";
import {AdminUserCreate} from "./users/admin-users/Create";
import {AdminUserEdit} from "./users/admin-users/Edit";
import {UserPermission} from "./users/admin-users/UserPermission";
import {AdminUserChangePassword} from "./users/admin-users/ChangePassword";
import {is_permit} from "./common/Common";
import {ErrorPage} from "./error/ErrorPage";
import {Web} from "./layout/Web";

import {LanguageListing} from "./languages/language/Listing";
import {CustomerChangePassword} from "./users/customers/ChangePassword";
import {CustomerTabs} from "./users/customers/CustomerTabs";

import {LanguageEdit} from "./languages/language/Edit";
import {LanguageCreate} from "./languages/language/Create";
import {EditProfile} from "./users/profile/edit";

import {EmailListing} from "./email/email/Listing";
import {SystemEmailListing} from "./email/system/Listing"; 
import {EmailCreate} from "./email/email/Create";

import {CommonListing} from "./settings/common-setting/Listing";
import {CommonEdit} from "./settings/common-setting/Edit";
import {CommonCreate} from "./settings/common-setting/Create";
import {NotificationListing} from "./systemInfo/notification/Listing";

import {UserGrid} from "./users";
import {SettingGrid} from "./settings/settings";
import {NotFound} from "./NotFound";

import {NewletterGrid} from "./email";
import {SystemInfoGrid} from "./systemInfo";
import { CookiesProvider, withCookies, Cookies } from 'react-cookie';
import moment from "moment-timezone";
import Login from "./Auth/Login";
import { WeFileViewer } from './we-file-viewer/WeFileViewer';
import {Pillars} from "./pillars/pillars";
import {PillarUpdate} from "./pillars/pillars/Edit";
import {Concepts} from "./pillars/concept";
import {ConceptCreate} from "./pillars/concept/Create";
import {ConceptUpdate} from "./pillars/concept/Edit";
import {Questionnaire} from "./quiz/questionnaire";
import {QuestionnaireCreate} from "./quiz/questionnaire/Create";
import {QuestionnaireEdit} from "./quiz/questionnaire/Edit";
import {PillarGrid} from "./pillars";
import {QuizGrid} from "./quiz";
import {Pages} from "./settings/pages";
import {PageUpdate} from "./settings/pages/Edit";
import {Clubs} from "./clubs/clubs";
import {ClubCreate} from "./clubs/clubs/Create";
import {ClubUpdate} from "./clubs/clubs/Edit";
import {Teams} from "./clubs/team";
import {TeamCreate} from "./clubs/team/Create";
import {TeamUpdate} from "./clubs/team/Edit";
import {ClubGrid} from "./clubs";
import {CustomerImport} from "./clubs/team/Import";
import {ClubImport} from "./clubs/clubs/ClubImport";
import { QuestionnaireCopy } from './quiz/questionnaire/Copy';
import { ClubSettings } from './clubs/clubs/ClubSettings';
import { Sports } from './quiz/sport';
import { SportCreate } from './quiz/sport/Create';
import { SportEdit } from './quiz/sport/Edit';
import { AnsFeedbackCreate } from './quiz/ans-feedback/Create';
import { AnsFeedback } from './quiz/ans-feedback';
import { QuesTypes } from './quiz/ques-type';
import { QuesTypesCreate } from './quiz/ques-type/Create';
import { QuesTypesEdit } from './quiz/ques-type/Edit';
import { Popups } from './quiz/popups';
import { PopupEdit } from './quiz/popups/Edit';
import { PopupInfo } from './quiz/popups/PopupInfo';
import { Resources } from './quiz/resources';
import { ResourceCreate } from './quiz/resources/Create';
import { ResourceEdit } from './quiz/resources/Edit';
import { ResourceCopy } from './quiz/resources/Copy';
import { SystemNotifications } from './settings/notifictions/Index';
import { CreateSystemNotification } from './settings/notifictions/create';
import { EditSystemNotification } from './settings/notifictions/edit';
declare const window: any;



interface IProps {
  exact?: boolean;
  path: string;
  scope: string|Array<string>;
  component: React.ComponentType<any>;
}

class Index extends React.Component<any, any> {


  constructor(props){
    super(props);
    moment.tz.setDefault();
    this.state = {
      isAuthenticated: false,
      is_loading: true
    };
  }
  componentDidMount() {
    ($(".selectpicker") as any).selectpicker();
    const {cookies}: any = this.props;
    Auth.reAuthenticate(cookies.get('remember_token')).then(res => {
      if(res){
        if(res.role_id == 1 || res.role_id > 3){
          this.setState({
            isAuthenticated: true,
            is_loading: false
          }, () => {window.KTLayout.init()});
        } else {
          this.setState({
            is_loading: false
          })
        }

      } else {
        this.setState({
          is_loading: false
        })
      }
    });
  }

  render() {

    if (window.location.pathname === '/login' && this.state.isAuthenticated) {
      window.location.href = '/';
    }

    if(this.state.is_loading){
      return null;
    }

    if(!this.state.isAuthenticated){
      return <Login />
    }


    const PermitRoute = ({ component: Component, ...rest }: IProps) => (
      <Route {...rest} render={(props) => (
        is_permit(rest.scope)
          ? <Component {...props} />
          : <ErrorPage  />
      )} />
    );

    return(
      <CookiesProvider>
        <Router>
        <Web>
          <Switch>
          <Route path="/" exact component={Dashboard} />
          <PermitRoute exact path="/users" component={UserGrid} scope={['customers.index', 'admin-users.index']} />
          <PermitRoute exact path="/users/admin-users" component={AdminUsers} scope={'admin-users.index'} />
          <PermitRoute path="/users/admin-users/create" component={AdminUserCreate} scope={'admin-users.store'} />
          <PermitRoute exact path="/users/admin-users/edit/:id" component={AdminUserEdit} scope={'admin-users.update'} />
          <PermitRoute path="/users/admin-users/edit/:id/permissions" component={UserPermission} scope={'admin-users.update'} />
          <PermitRoute path="/users/admin-users/edit/:id/change-password" component={AdminUserChangePassword} scope={'admin-users.update'} />

          {/* Customer routes*/}
          <PermitRoute exact path="/users/users/"  component={Listing} scope={'users.index'} />
          <PermitRoute exact path="/users/users/create" component={CustomerCreate} scope={'users.store'} />
          <PermitRoute path="/users/users/info/:id" component={CustomerTabs} scope={'users.update'} />


          {/*<PermitRoute exact path="/users/customers/:id/orders" component={CustomerOrders} scope={'order.index'} />*/}

          <PermitRoute path="/users/users/change-password/:id" component={CustomerChangePassword} scope={''}/>
          {/* Customer routes end*/}

          
          <PermitRoute path="/profile/edit/" component={EditProfile} scope={''}/>


          <PermitRoute exact path="/pillars" component={PillarGrid} scope={['pillars.index', 'concept.index']} />
          <PermitRoute exact path="/pillars/pillars"  component={Pillars} scope={'pillars.index'} />
          <PermitRoute exact path="/pillars/pillars/edit/:id"  component={PillarUpdate} scope={'pillars.index'} />
          <PermitRoute exact path="/pillars/concepts"  component={Concepts} scope={'concept.index'} />
          <PermitRoute exact path="/pillars/concepts/create"  component={ConceptCreate} scope={'concept.store'} />
          <PermitRoute exact path="/pillars/concepts/edit/:id"  component={ConceptUpdate} scope={'concept.update'} />


          <PermitRoute exact path="/clubs" component={ClubGrid} scope={['clubs.index', 'teams.index']} />
          <PermitRoute exact path="/clubs/clubs"  component={Clubs} scope={'clubs.index'} />
          <PermitRoute exact path="/clubs/clubs/create"  component={ClubCreate} scope={'clubs.store'} />
          <PermitRoute exact path="/clubs/clubs/import/" component={ClubImport} scope={'clubs.store'} />
          <PermitRoute exact path="/clubs/clubs/edit/:id"  component={ClubUpdate} scope={'clubs.index'} />
          <PermitRoute exact path="/clubs/clubs/:id/settings"  component={ClubSettings} scope={'club-settings.store'} />
          <PermitRoute exact path="/clubs/teams"  component={Teams} scope={'teams.index'} />
          <PermitRoute exact path="/clubs/teams/create"  component={TeamCreate} scope={'teams.store'} />
          <PermitRoute exact path="/clubs/teams/edit/:id"  component={TeamUpdate} scope={'teams.index'} />
          <PermitRoute exact path="/clubs/teams/import/:id" component={CustomerImport} scope={'teams.store'} />



          <PermitRoute exact path="/quiz" component={QuizGrid} scope={['questionnaire.index']} />
          <PermitRoute exact path="/quiz/questionnaire"  component={Questionnaire} scope={'questionnaire.index'} />
          <PermitRoute exact path="/quiz/questionnaire/create"  component={QuestionnaireCreate} scope={'questionnaire.store'} />
          <PermitRoute exact path="/quiz/questionnaire/edit/:id"  component={QuestionnaireEdit} scope={'questionnaire.update'} />
          <PermitRoute exact path="/quiz/questionnaire/copy/:id"  component={QuestionnaireCopy} scope={'questionnaire.store'} />

          <PermitRoute exact path="/quiz/sports"  component={Sports} scope={'sports.index'} />
          <PermitRoute exact path="/quiz/sports/create"  component={SportCreate} scope={'sports.store'} />
          <PermitRoute exact path="/quiz/sports/edit/:id"  component={SportEdit} scope={'sports.update'} />

          <PermitRoute exact path="/quiz/ques-types"  component={QuesTypes} scope={'ques-types.index'} />
          <PermitRoute exact path="/quiz/ques-types/create"  component={QuesTypesCreate} scope={'ques-types.store'} />
          <PermitRoute exact path="/quiz/ques-types/edit/:id"  component={QuesTypesEdit} scope={'ques-types.update'} />

          <PermitRoute exact path="/quiz/popups"  component={Popups} scope={'popups.index'} />
          <PermitRoute exact path="/quiz/popups/edit/:id"  component={PopupEdit} scope={'popups.update'} />
          <PermitRoute exact path="/quiz/popups/info/:id"  component={PopupInfo} scope={'popups.index'} />

          <PermitRoute exact path="/quiz/ans-feedback"  component={AnsFeedback} scope={'ans-feedback.index'} />
          <PermitRoute exact path="/quiz/ans-feedback/create"  component={AnsFeedbackCreate} scope={'ans-feedback.store'} />
          {/* <PermitRoute exact path="/quiz/ans-feedback/edit/:id"  component={SportEdit} scope={'sports.update'} /> */}

          <PermitRoute exact path="/quiz/resources"  component={Resources} scope={'resources.index'} />
          <PermitRoute exact path="/quiz/resources/create"  component={ResourceCreate} scope={'resources.store'} />
          <PermitRoute exact path="/quiz/resources/edit/:id"  component={ResourceEdit} scope={'resources.update'} />
          <PermitRoute exact path="/quiz/resources/copy/:id"  component={ResourceCopy} scope={'resources.store'} />


          <PermitRoute exact path="/settings" component={SettingGrid} scope={['email-template.index', 'common-setting.index']} />
          <PermitRoute exact path="/settings/email-templates/" component={EmailTemplates} scope={'email-template.index'} />
          <PermitRoute path="/settings/email-templates/create" component={CreateEmailTemplates} scope={'email-template.store'} />
          <PermitRoute path="/settings/email-templates/edit/:id" component={EditEmailTemplates} scope={'email-template.update'} />

          <PermitRoute exact path="/settings/system-notifications/" component={SystemNotifications} scope={'system-notifications.index'} />
          <PermitRoute path="/settings/system-notifications/create" component={CreateSystemNotification} scope={'system-notifications.store'} />
          <PermitRoute path="/settings/system-notifications/edit/:id" component={EditSystemNotification} scope={'system-notifications.update'} />


          <PermitRoute exact path="/settings/pages/" component={Pages} scope={'pages.index'} />
          <PermitRoute exact path="/settings/pages/edit/:id" component={PageUpdate} scope={'pages.update'} />


          <PermitRoute exact path="/permission/role" component={RolesListing} scope={'role.index'} />
          <PermitRoute path="/permission/role/create" component={RoleCreate} scope={'role.store'} />
          <PermitRoute path="/permission/role/edit/:id" component={RoleEdit} scope={'role.update'} />
          
          <PermitRoute exact path="/languages/language" component={LanguageListing} scope={'language.index'} />
          <PermitRoute path="/languages/language/edit/:id" component={LanguageEdit} scope={'language.edit'} />
          <PermitRoute path="/languages/language/create" component={LanguageCreate} scope={'language.create'} />

          <PermitRoute exact path="/email" component={NewletterGrid} scope={['email.index','system-email.index']} />
          <PermitRoute exact path="/email/email" component={EmailListing} scope={'email.index'} />
          <PermitRoute path="/email/email/create" component={EmailCreate} scope={'email.store'} />
            <PermitRoute exact path="/email/system" component={SystemEmailListing} scope={'system-email.index'} />
            {/*<PermitRoute exact path="/email/system" component={SystemEmailListing} scope={'system-email.index'} />*/}
            

          <PermitRoute exact path="/settings/common-setting" component={CommonListing} scope={'common-setting.index'} />
          <PermitRoute path="/settings/common-setting/edit/:id" component={CommonEdit} scope={'common-setting.update'} />
          <PermitRoute path="/settings/common-setting/create" component={CommonCreate} scope={'common-setting.store'} />
          <PermitRoute exact path="/systemInfo/notification" component={NotificationListing} scope={'notification.index'} />
          <PermitRoute exact path="/systemInfo" component={SystemInfoGrid} scope={['notification.index','enquiries.index','activity-log.index']} />
          
		      <PermitRoute path="/logs/activity-logs/" component={ActivityLogs} exact scope={'activity-log.index'} />
          
          <PermitRoute exact path='/file-view/:id/:documentType/' scope={'orders.index'} component={WeFileViewer} />

          <Route path="*" component={NotFound} />
          </Switch>
       </Web>
      </Router>
      </CookiesProvider>
    )
  }
}

export default withCookies(Index);