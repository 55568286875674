import Lang from "./lang/Lang";
import { Icons } from "./Icon";

export interface MenuTitleSchema {
  title: string;
  icon: string;
  link?: string;
  scope?: string | Array<string>;
  singlemenu?: number;
  menu?: Array<MenuSchema>;
}

export interface MenuSchema {
  label: string;
  link?: string;
  icon?: string;
  scope?: string;
}

export const menu = [
  {
    title: Lang.users,
    icon: Icons.users2,
    scope: ["users", "admin-users"],
    menu: [
      { label: "Front Users", link: "/users/users", scope: "users" },
      {
        label: Lang.admin_users,
        link: "/users/admin-users",
        scope: "admin-users",
      },
    ],
  },

  {
    title: "Pillar",
    icon: Icons.bank,
    scope: ["pillars", "concepts"],
    menu: [
      { label: "Pillars", link: "/pillars/pillars", scope: "pillars" },
      { label: "Concepts", link: "/pillars/concepts", scope: "concepts" },
    ],
  },
  {
    title: "Club",
    icon: Icons.club,
    scope: ["clubs", "teams"],
    menu: [
      { label: "Clubs", link: "/clubs/clubs", scope: "clubs" },
      { label: "Teams", link: "/clubs/teams", scope: "teams" },
    ],
  },
  {
    title: "Profile",
    icon: Icons.question,
    scope: ["questionnaire"],
    menu: [
      {
        label: "Questionnaire",
        link: "/quiz/questionnaire",
        scope: "questionnaire",
      },
      {
        label: "Sports",
        link: "/quiz/sports",
        scope: "sports",
      },
      {
        label: "Feedback",
        link: "/quiz/ans-feedback",
        scope: "ans-feedback",
      },
      // {
      //   label: "Questionnaire Types",
      //   link: "/quiz/ques-types",
      //   scope: "ques-types",
      // },
      {
        label: "Popups",
        link: "/quiz/popups",
        scope: "popups",
      },
      {
        label: "Resources",
        link: "/quiz/resources",
        scope: "resources",
      },
    ],
  },
  {
    title: Lang.settings,
    icon: Icons.setting,
    scope: ["email-template", "common-setting", "pages"],
    menu: [
      { label: "Pages", link: "/settings/pages", scope: "pages" },
      {
        label: "Notifictions",
        link: "/settings/system-notifications",
        scope: "system-notifications",
      },
      {
        label: Lang.email_templates,
        link: "/settings/email-templates",
        scope: "email-template",
      },
      {
        label: Lang.common_setting,
        link: "/settings/common-setting",
        scope: "common-setting",
      },
    ],
  },
  {
    title: "Roles",
    icon: Icons.permission,
    link: "/permission/role",
    scope: "role",
    singlemenu: 1,
  },
  {
    title: Lang.newletter,
    icon: Icons.email,
    scope: ["email", "system-email"],
    menu: [
      { label: Lang.email, link: "/email/email", scope: "email.index" }, //{label: Lang.system_email,  link: '/email/system', scope: 'system-email'}, commented - 01-04-2020 permission issue
      //      {label: Lang.system_email,  link: '/email/system', scope: 'system-email.index'},
    ],
  },
  {
    title: Lang.system_info,
    icon: Icons.view,
    scope: ["activity-log", "notification", "enquiries", "calllog"],
    menu: [
      {
        label: Lang.activity_logs,
        link: "/logs/activity-logs",
        scope: "activity-log",
      },
      {
        label: Lang.notification,
        link: "/systemInfo/notification",
        scope: "notification",
      },
    ],
  },
];
