import * as React from 'react';
import {CreateBreadcrumb, IState} from "./Data";
import {RouteComponentProps} from "react-router";
import {CreateLayout} from "../../layout/CreateLayout";
import { NotificationForm } from './form/NotificationForm';
import { SystemNotificationModel } from '../../model';
import { SystemNotificationService } from '../../services/SystemNotificationService';


export class EditSystemNotification extends React.Component<RouteComponentProps, IState > {
  state: IState;

  constructor(props:any){
    super(props);
    this.state = {
      id: props.match.params.id,
    }
  }

  init = async () => {
      const data: SystemNotificationModel = await SystemNotificationService.getInfo(this.state.id);
      this.setState({
        data: data
      })
    }
  
    componentDidMount() {
      this.init();
    }
  

  render() {
    return (
        <CreateLayout breadcrumb={CreateBreadcrumb} title={'Add'}>
          <NotificationForm {...this.state}/>
        </CreateLayout>
    )
  }
}