import * as React from 'react';
import {CreateBreadcrumb, IState} from "./Data";
import {RouteComponentProps} from "react-router";
import {CreateLayout} from "../../layout/CreateLayout";
import { NotificationForm } from './form/NotificationForm';


export class CreateSystemNotification extends React.Component<RouteComponentProps, IState > {
  state: IState;

  constructor(props:any){
    super(props);
    this.state = {
      id: props.match.params.id,
    }
  }

  render() {
    return (
        <CreateLayout breadcrumb={CreateBreadcrumb} title={'Add'}>
          <NotificationForm {...this.state}/>
        </CreateLayout>
    )
  }
}