import { EmailTemplateDataSchema } from "../settings/email-templates/Model";

export interface SystemEventModel {
  id: number;
  title: string;
  event_class: string;
  description: string;
  status: number;
}

export enum NotificationRecurrence {
  ONCE = "once",
  DAILY = "daily", 
  WEEKLY = "weekly", 
  MONTHLY = "monthly", 
  BIMONTHLY = "bi-monthly", 
  QUARTERLY = "quarterly", 
  YEARLY = "yearly"
}

export interface SystemNotificationModel {
  id: number;
  title: string;
  system_event_id: number;
  recurrence: NotificationRecurrence;
  created_by: number;
  last_executed_at: string;
  status: number;
  created_at : string;
  email_template_id?: Array<number>;

  system_event?: SystemEventModel;
  email_templates?: Array<EmailTemplateDataSchema>;
}



export interface NotificationFormData {
  events: Array<SystemEventModel>;
  email_templates: Array<EmailTemplateDataSchema>;
}
