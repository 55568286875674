import React from "react";
import _ from "lodash";
import {CommonSubmitButton, isEmpty, LoaderType, RenderForm, showNotification, SwitchLoader} from "../../../common";
import { useHistory } from "react-router-dom";
import {NotificationFormData, SystemNotificationModel} from "../../../model";
import {IState} from "../Data";
import {ClubService} from "../../../services";
import { SystemNotificationService } from "../../../services/SystemNotificationService";


export const NotificationForm = (props: IState) => {
  let history = useHistory();
  const [form, setForm] = React.useState({} as SystemNotificationModel);
  const [data, setData] = React.useState({} as any);

  const handleChange = (event: React.ChangeEvent<HTMLElement>) => {
    const target : any = event.target;
    const {name, value} = target;
    setForm({...form, [name]:  value})
  }

  const handleSelect2 = field => (inputValue) => {
    console.log(inputValue)
    setForm({...form, [field]:  inputValue})
  }

  React.useEffect(() => {
    const {data} = props;
    if(data){
      data["email_templates"] =  defaultEmailtemplates(data.email_templates) ;
      setForm(data)
    }
   
  },[props.data])

  React.useEffect(() => {
    async function init() {
      const dataForForm: NotificationFormData = await SystemNotificationService.dataForNotificationForm();
      setData(dataForForm)
    }
    init()
  },[])

  const loadEmailTemplates = () => (inputValue, callback) => {
    callback(_.get(data, "email_templates", []));
  }

  function defaultEmailtemplates(email_templates) {
    let templates = [];
    const oldMembers = email_templates;
    if(oldMembers){
      // @ts-ignore
      oldMembers.map(item => templates.push({value: item.id, id: item.id, label: item.name}))
    }
    return templates;
  }

  const FormInputs = [
    {
      "name" : "title",
      "value" : _.get(form, "title", ""),
      "onChange": handleChange,
      "required": true,
      "placeholder": "Enter notification title",
      "label": "Notification Title",
    },
    {
      "name" : "system_event_id",
      "value" : _.get(form, "system_event_id", ""),
      "onChange": handleChange,
      "required": true,
      "type": "select",
      "placeholder": "Select Event",
      "label": "Event",
      "options" : _.get(data, "events", [])
    },
    
    {
      "name" : "recurrence",
      "value" : _.get(form, "recurrence", ""),
      "onChange": handleChange,
      "required": true,
      "type": "select",
      "placeholder": "Select recurrence",
      "label": "Recurrence",
      "options" : _.get(data, "recurrence", [])
    },
    {
      "name" : "email_templates",
      "value" : _.get(form, "email_templates", []),
      "onChange": handleSelect2("email_templates"),
      "required": true,
      "type": "select2",
      "placeholder": "Select Email Template",
      "label": "Email Template",
      "isMulti": true,
      "loadOptions" : loadEmailTemplates()
    },

  ];

  function isDisable() {
    return FormInputs.reduce((acc, val) => acc ? acc : isEmpty(val.value) && val.required, false);
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);

    const payload = {
      ...form,
      email_templates: form.email_templates.map((item) => item.id)
    }
    if(props.id){
      SystemNotificationService.update(payload, props.id).then((message) => {
        showNotification("success", message);
        history.push(`/settings/system-notifications`)
      }).catch(() => {});
    } else {
      SystemNotificationService.store(payload).then((message) => {
        showNotification("success", message);
        history.push(`/settings/system-notifications`)
      }).catch(() => {});
    }
  }

    return(
      <React.Fragment>
        <form className="kt-form" id={'system_notification_form'} onSubmit={handleSubmit}>
          <div className="kt-portlet__body">
            <div className={'row'}>
              <RenderForm items={FormInputs}/>
            </div>
          </div>
          <CommonSubmitButton disabled={isDisable()}/>
        </form>
      </React.Fragment>
      )
}