import API from "../common/AxoisClient";
import {state_to_form_data} from "../helpers/UserHelper";
import {ClubSettingsModel, UserModel} from "../model";

export class ClubService {

  /**
   * get pillers list
   */
  static async getClubInfo(id: number){
    const res = await API.get(`clubs/clubs/${id}`);
    return res.data
  }

  static async coachDirectorsList (params) {
    const users = await API.get('clubs/clubs/coach-directors-list', {params});
    return users.data;
  }


  static async playersList (q: string, age_group_id) {
    const users = await API.get('clubs/teams/players-list', {params: {q, age_group_id}});
    return users.data;
  }

  static async coachesList () {
    const users = await API.get('clubs/teams/coach-list');
    return users.data;
  }

  static async clubsList () {
    const users = await API.get('clubs/clubs/clubs-list');
    return users.data;
  }
  

  static async storeClub(form) {
    const store = await API.post("clubs/clubs", (form));
    return  store.data;
  }

  static async updateClub(form, id) {
    const store = await API.post(`clubs/clubs/${id}`, ({...form, '_method': 'PUT'}));
    return  store.data;
  }


  static async storeTeam(form) {
    const store = await API.post("clubs/teams", form);
    return  store.data;
  }

  static async updateTeam(form, id) {
    const store = await API.post(`clubs/teams/${id}`, {...form, '_method': 'PUT'});
    return  store.data;
  }


  static async dataForTeam() {
    const store = await API.get("clubs/teams/create");
    return  store.data;
  }

  static async getTeamInfo(id: number){
    const res = await API.get(`clubs/teams/${id}`);
    return res.data
  }

  static async addPlayer(form: UserModel){
    const res = await API.post(`clubs/teams/add-player`, form);
    return res.data
  }


  //  club settings services
  static async getClubSettings(clubId: number) {
    const res = await API.get(`clubs/club-settings/${clubId}`);
    return res.data
  }

  static async storeClubSettings(form: ClubSettingsModel, clubId: number) {
    const store = await API.post(`clubs/club-settings/${clubId}/create`, (form));
    return  store.data;
  }

  static async updateClubSettings(form: ClubSettingsModel, clubId: number, id: number) {
    const store = await API.post(`clubs/club-settings/${clubId}/${id}/update`, ({...form, '_method': 'PUT'}));
    return  store.data;
  }

  static async teamsList(params: any) {
    const store = await API.get(`clubs/teams/team-list`, { params } );
    return  store.data;
  }
  
}