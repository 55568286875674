import * as React from 'react';
import {IState as CommonState} from "../../common/State";
import API from "../../common/AxoisClient";
import Lang from "../../common/lang/Lang";
import Swal from "sweetalert2";
import {RouteComponentProps} from "react-router";
import {ListBreadcrumb} from "./Data";
import EmailTemplateFilter from './filter/EmailTemplateFilter';
import _ from "lodash";
import {list_key} from "../../helpers/OrderHelper";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
import {actionButton, Icons, ShowRecords, CreateLink, EditLink, ExportButton, showNotification} from "../../common";
import {humanifyDate} from "../../helpers/UserHelper";
import { SystemNotificationModel } from '../../model';
import { EmailTemplateDataSchema } from '../email-templates/Model';

declare var window:any;

interface IState extends CommonState {
    UserData: Array<SystemNotificationModel>;
   
}

export class SystemNotifications extends React.Component<RouteComponentProps, IState> {
    state: IState;
    records:React.RefObject<ShowRecords> = React.createRef();

    shouldComponentUpdate(nextProps: Readonly<RouteComponentProps>, nextState: Readonly<IState>, nextContext: any): boolean {
      
        return  this.state !== nextState;
         
     }

    createMarkup =(content: string) => {
        return { __html: content };
    }

    showContent = (data: SystemNotificationModel, index: number) => {
      let cols = [];
      cols.push(...[
        <td key={index}>{this.records.current.checkbox(data.id)}</td>,
        this.records.current.createdTd("title", data.title),
        this.records.current.createdTd("system_event", data.system_event.title),
        this.records.current.createdTd("recurrence", data.recurrence),
        this.records.current.createdTd("email_templates", () => {
          let email_templates = data.email_templates.map((email_template: EmailTemplateDataSchema) => email_template.name);
         // return this.createMarkup(email_templates.join(", </br>"));
          return <span dangerouslySetInnerHTML={ this.createMarkup(email_templates.join(", </br>")) }/>
        }),
       
        this.records.current.createdTd("status", () => {
          if(data.status === 1){return <span className={'badge badge-primary'}>{Lang.active}</span>;}
          if(data.status === 2){return <span className={'badge badge-warning'}>{Lang.inactive}</span>}
        }),
        this.records.current.createdTd("created_at", humanifyDate(data.created_at)),
        this.records.current.createdTd("action", () => {
          return <div>
            <EditLink scope={'email-template.update'} to={`${this.props.match.url}/edit/${data.id}`}  title={Lang.edit_email_template} />
          </div>;
        })
      ]);
      cols = _.compact(cols);
        return (
            <tr key={list_key(data.id)}>{cols}</tr>
        );
    }

  
    filterRecords = (data?: any) => {
        if( this.records && this.records.current !== null){
          if(data !== undefined){
            this.records.current.setFormFields(data);
          }else {
            this.records.current.setFormFields();
          }
        }
      }

     

    actions = (actionType: string) => {
        let typeText;
        switch (actionType) {
            case "active":typeText = Lang.active_confirm;break;
            case "inactive":typeText = Lang.inactive_confirm;break;
            case "delete":typeText = Lang.delete_confirm;break;
        }
        Swal.fire({
            title: Lang.is_sure,text: typeText,type: 'warning',showCancelButton: true,confirmButtonColor: '#3085d6',cancelButtonColor: '#d33',confirmButtonText: 'Ok'
        }).then((result) => {
            if (result.value) {
                let ids: any = this.records.current.state.selected;
                API.post("emailtemplates/email-temp-action", {ids, actionType: actionType}).then(response => {
                    showNotification("success", response.data.message);
                    this.records.current.fetchData();
                });
            }
        })
    }

    render() {


        return (
            <div>

              <ListingLayout breadcrumb={ListBreadcrumb}>
                <ListHeader title={"System Notifications"} icon={Icons.email} >
                  <ExportButton records={this.records} fileName={"System Notifications"}/>
                  &nbsp;
                  <CreateLink to={`${this.props.match.url}/create`} scope={'system-notifications.store'} />
                </ListHeader>

                <ListForm
                  form={<EmailTemplateFilter  getResults={this.filterRecords} />}
                  table={(
                    <ShowRecords
                      url={"/settings/system-notifications"}
                      showRecords={this.showContent}
                      ref={this.records}
                      isNeedAction={true}
                      showCheckbox={false}
                      actionButtons={actionButton({records: this.records, 'url': "settings/system-notifications/bulk-action"})}
                      //rowActions={this.rowActionButtons}
                    />
                  )}
                />
              </ListingLayout>

                
            </div>
        )
    }
}
