import React  from 'react';
import {CustomerProps, UserDataSchema} from "./Model";
import Lang from "../../common/lang/Lang"
import {
  Icons, IState as CommonState, ShowRecords, ExportButton,
  CreateLink, EditLink, showConfirmBox, CONST, actionButton, GeneratePermitLink
} from "../../common";
import {CustomerBreadcrumb} from "./Data";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
import {CustomerInfo} from "./CustomerInfo";
import {AgeGroupModel, ClubModel, RoleModel, TeamModel, UserModel} from "../../model";
import _ from "lodash";
import API from "../../common/AxoisClient";
import CustomerFilter from './filter/CustomerFilter';
import {list_key} from "../../helpers/OrderHelper";
import {UserService} from "../../services/UserService";
import {ClubService, QuestionnaireService} from "../../services";
import {CustomerActions} from "./CustomerActions";
import {UserClubAndTeam} from "./Side";
const moment = require("moment");


declare var window:any;




interface IState extends CommonState{
  UserData: Array<UserDataSchema>;
  ModalData?: UserModel;
  show?: boolean;
  roles: Array<RoleModel>;
  age_groups: Array<AgeGroupModel>;
  clubs: Array<ClubModel>;
  teams: Array<TeamModel>;
}


export class Listing extends React.Component<CustomerProps, IState> {
  state: IState;
  records:React.RefObject<ShowRecords> = React.createRef();

 

  componentDidMount() {
    const ___that = this;
    async function  init() {
      const userRoles = await UserService.getRoles();
      const ageGroupList = await QuestionnaireService.getAgeGroups();
      const clubList = await ClubService.clubsList();
      ___that.setState({
        show: false,
        roles: [{"label": "Select Role", "value": ""}, ...userRoles],
        age_groups: [{"label": "Select Age group", "value": ""}, ...ageGroupList],
        clubs: [{"label": "Select Club", "value": ""}, ...clubList],
        teams: [{"label": "Select Team", "value": ""}, ...clubList],
      })
    }
    init();
}
 

  handleChangeStatus = (event : React.ChangeEvent<any>,id:number,action:number)=>{
    const actions = action === 1 ? 'inactive' : "active";
    showConfirmBox("Are you sure that you want to change user status?", async () => {
      API.post('users/users/bulk-action',{action:actions,ids:[id]}).then(res => {
       this.filterRecords();
      });
    })
  }

  userAgeGroup = (user: UserModel) => {
    if(user.age_group){
      return <React.Fragment><span ><b>Age Group</b> : {user.age_group.title}</span></React.Fragment>
    }
    return null;
  }

  showContent = (data: UserModel, index: number) => {
   
   let cols = [];
    cols.push(...[
      <td key={index}>{this.records.current.checkbox(data.id)}</td>,
      this.records.current.createdTd("id", index),
      this.records.current.createdTd("role_id", () => {
        return (
          <div>
            {data.role.name}
            <div className="kt-widget__body">
              <div className="kt-widget__content">
                <UserClubAndTeam user={data}/>
              </div>
            </div>
          </div>
        )
      }),
      this.records.current.createdTd("first_name", data.name),
      this.records.current.createdTd("mobile_no", data.mobile_no),
      this.records.current.createdTd("email", function(){
        return (
          <div>
            <span>{data.email}</span>
            { data.email_verified_at ==	null && <span style={{marginLeft: 5}} data-skin={'dark'} data-toggle={'kt-tooltip'} title={'Unverfied'}><i style={{color: 'red'}} className='la la-times-circle'></i></span>}
            { data.email_verified_at !=	null && <span style={{marginLeft: 5}} data-skin={'dark'} data-toggle={'kt-tooltip'} title={'Verified'}><i style={{color: 'green'}} className='la la-check-circle'></i></span>}
          </div>
        );
      }),
      this.records.current.createdTd("customer_info", () =>  {
        return (
          <div style={{width:'200px'}}>
            <span><b>Username: </b> {data.username}</span>
            <br/>      
            {this.userAgeGroup(data)}
            <br/>
             <span ><b>{Lang.account_status}</b> :
               { data.status === 1 &&
                  <span className={'badge badge-primary'}>{Lang.active}</span>
               }
               {data.status === 2 &&
                <span className={'badge badge-warning'}>{Lang.inactive}</span>
               }
             </span>
            <br/>            
           { data.status === 1 &&
            <input type="button" className={'btn btn-sm btn-warning'} onClick={(e) =>this.handleChangeStatus(e, data.id,data.status)} value={Lang.deactivate}/>
              }
              { data.status === 2 &&
           <input type="button" className={'btn btn-sm btn-primary'} onClick={(e) =>this.handleChangeStatus(e, data.id,data.status)} value={Lang.active}/>
              }
          </div>
        )
      }),
      
    
      this.records.current.createdTd("action", () =>  {
        return (
          <div>
            <EditLink scope={'customers.update'} to={`/users/users/info/${data.id}`}   title={Lang.edit_customer} />
            <CustomerActions customer_data={data.id} />
          </div>
        )
      })
    ]);
    cols = _.compact(cols);

    return (
      <tr key={list_key(data.id)}>{cols}</tr>
    );
  }

  filterRecords = (data?: any) => {
    if( this.records && this.records.current !== null){
      if(data !== undefined){
        this.records.current.setFormFields(data);
      }else {
        this.records.current.setFormFields();
      }
    }
  }

 

  render() {
    const status = [{'label': "Select Status", "value": ""}, {'label': "Active", "value": 1}, {'label': "Inactive", "value": 2}];
    return (
      <ListingLayout breadcrumb={CustomerBreadcrumb}>
          <ListHeader title={'Front Users'} icon={Icons.users} >
            <ExportButton records={this.records} fileName={Lang.exportCustomers}/>
            &nbsp;
            <CreateLink to={`/users/users/create`} scope={'users.store'} />
          </ListHeader>

          <ListForm
            form={(
              <CustomerFilter
                roles={ _.get(this.state, "roles", []) }
                age_groups={ _.get(this.state, "age_groups", []) }
                clubs={ _.get(this.state, "clubs", []) }
                status={status}  getResults={this.filterRecords} />
            )}
            table={(
              <ShowRecords
                url={"users/users"}
                showRecords={this.showContent}
                ref={this.records}
                isNeedAction={true}
                actionButtons={actionButton({
                  records: this.records, 'url': "users/users/bulk-action", "module": 'users',
                  extraActions: [{
                    label: 'Confirmation Email',
                    class: 'btn btn-sm btn-primary',                    
                    show: true
                  }]
                })}
                callBack={() => {
                  setTimeout(function(){
                    window.KTApp.initTooltips();
                  }, 1000)
                  
                }}
              />
            )}
          />
         {
            this.state !== null &&
            <CustomerInfo
              show={this.state.show}
              onHide={() => this.setState({show: false})}
              userinfo={this.state.ModalData}
            />
         }
        </ListingLayout>

    )
  }
}
